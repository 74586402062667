import React from 'react';
import { LaunchPadApp } from '@launchpad/app';
import configuration from './configuration';

require('./configuration/styles/overrides.scss');

function App() {
  return <LaunchPadApp configuration={configuration} />;
}

export default App;
